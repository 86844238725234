import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Label, Textbox } from 'components/Form';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { LabelWrapper } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/LabelWrapper" title="LabelWrapper" mdxType="PageTitle" />
    <h2 {...{
      "id": "horizonal"
    }}>{`Horizonal`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <LabelWrapper\n    input={<Textbox value=\"Hello world\" variant=\"filled\" />}\n    label={<Label strength=\"standard\">Super awesome label</Label>}\n  />\n  <br />\n  <br />\n  <LabelWrapper\n    fullWidth\n    spread\n    input={<Textbox value=\"Hello world\" variant=\"filled\" />}\n    label={<Label strength=\"standard\">Super awesome label</Label>}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Label,
      Textbox,
      PageTitle,
      ThemeWrapper,
      LabelWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LabelWrapper input={<Textbox value="Hello world" variant="filled" mdxType="Textbox" />} label={<Label strength="standard" mdxType="Label">Super awesome label</Label>} mdxType="LabelWrapper" />
    <br />
    <br />
    <LabelWrapper fullWidth spread input={<Textbox value="Hello world" variant="filled" mdxType="Textbox" />} label={<Label strength="standard" mdxType="Label">Super awesome label</Label>} mdxType="LabelWrapper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "horizonal-reverse"
    }}>{`Horizonal reverse`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <LabelWrapper\n    reverse\n    input={<Textbox value=\"Hello world\" variant=\"filled\" />}\n    label={<Label strength=\"standard\">Super awesome label</Label>}\n  />\n  <br />\n  <br />\n  <LabelWrapper\n    fullWidth\n    reverse\n    spread\n    input={<Textbox value=\"Hello world\" variant=\"filled\" />}\n    label={<Label strength=\"standard\">Super awesome label</Label>}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Label,
      Textbox,
      PageTitle,
      ThemeWrapper,
      LabelWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LabelWrapper reverse input={<Textbox value="Hello world" variant="filled" mdxType="Textbox" />} label={<Label strength="standard" mdxType="Label">Super awesome label</Label>} mdxType="LabelWrapper" />
    <br />
    <br />
    <LabelWrapper fullWidth reverse spread input={<Textbox value="Hello world" variant="filled" mdxType="Textbox" />} label={<Label strength="standard" mdxType="Label">Super awesome label</Label>} mdxType="LabelWrapper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "vertical"
    }}>{`Vertical`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <LabelWrapper\n    input={<Textbox value=\"Hello world\" variant=\"filled\" />}\n    label={<Label strength=\"standard\">Super awesome label</Label>}\n    orientation=\"vertical\"\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Label,
      Textbox,
      PageTitle,
      ThemeWrapper,
      LabelWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LabelWrapper input={<Textbox value="Hello world" variant="filled" mdxType="Textbox" />} label={<Label strength="standard" mdxType="Label">Super awesome label</Label>} orientation="vertical" mdxType="LabelWrapper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "vertical-reverse"
    }}>{`Vertical reverse`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <LabelWrapper\n    reverse\n    input={<Textbox value=\"Hello world\" variant=\"filled\" />}\n    label={<Label strength=\"standard\">Super awesome label</Label>}\n    orientation=\"vertical\"\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Label,
      Textbox,
      PageTitle,
      ThemeWrapper,
      LabelWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LabelWrapper reverse input={<Textbox value="Hello world" variant="filled" mdxType="Textbox" />} label={<Label strength="standard" mdxType="Label">Super awesome label</Label>} orientation="vertical" mdxType="LabelWrapper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={LabelWrapper} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      